import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>🙋‍♂️</h1>
        {/* <h2>Loland Braa</h2> */}
        <p>This is a placeholder for lolandbraa.no. <br></br>
          post@lolandbraa.no
        </p>
      </header>
    </div>
  );
}

export default App;
